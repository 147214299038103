<template lang="pug">
	v-app
		div
			v-app-bar(
				color="white"
				dark
			)
				//v-app-bar-nav-icon 
				v-toolbar-title(class="ml-5") 
					v-img(src="/imgs/logo.svg"  max-width="100")
					//- .flex-grow-1
				v-spacer
		v-main(color="ui-200")
			router-view
		v-footer(color="blue-800" min-height="100" class="text-center")
			v-container
				v-row
					v-col(class="text-center")
						v-img(src="/imgs/logo.svg"  max-width="100" class="mx-auto")
</template>
<script>
export default {
	name: 'Index',
	components: {
	//   DashboardCoreAppBar: () => import('./components/core/AppBar'),
	//   DashboardCoreView: () => import('../dashboard/components/core/View'),
	},
}
</script>